 import "es6-promise/auto";

 import {
     setContent
 } from "viewport-extra";

 var ua = navigator.userAgent;
 var isSmartPhone =
     ua.indexOf("iPhone") > -1 || (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1);

 var isTablet = !isSmartPhone &&
     (ua.indexOf("iPad") > -1 ||
         (ua.indexOf("Macintosh") > -1 && "ontouchend" in document) ||
         ua.indexOf("Android") > -1);

 window.addEventListener("DOMContentLoaded", (e) => {
     if (isSmartPhone) {
         setContent({
             minWidth: 375,
         });
     } else if (isTablet) {
         setContent({
             minWidth: 1200,
         });
     }
 });


 import $ from "jquery";
 /**
  * js_common
  *
  */
 import "./common";
 /**
  * js_slick
  *
  */
// import 'slick-carousel';
// $(function () {
//     var $jsSlick = $('.js_slick');
//     if ($jsSlick[0]) {
//         $('.js_slick').slick({
//             autoplay: true,
//             autoplaySpeed: 5000,
//             slidesToShow: 1,
//             slidesToScroll: 1,
//             dots: true,
//             arrow: true,
//             prevArrow: '<img src="/_assets/img/icons/icn_arrow.svg" class="slide-arrow prev-arrow">',
//             nextArrow: '<img src="/_assets/img/icons/icn_arrow.svg" class="slide-arrow next-arrow">',
//             responsive: [{
//                 breakpoint: 768,
//                 settings: {
//                     //            edge: true,
//                 }
//    }]
//         });
//     }
// });

 /**
  * js_bodyScrollLock
  *
  */
 //import {
 //    disableBodyScroll,
 //    enableBodyScroll,
 //    clearAllBodyScrollLocks
 //} from 'body-scroll-lock';

 /**
  * js_matchHeight
  *
  */
// import MatchHeight from 'matchheight';
// $(function () {
//     if ($('.ly_sample')[0]) {
//         $('.jsMatchHeight.bl_sample').matchHeight();
//         $('.jsMatchHeight.bl_sample').matchHeight();
//         if ($(window).width() > 751) {
//             $('.jsMatchHeight.bl_sample').matchHeight();
//         }
//     }
//
//     $(window).on('resize', function () {
//         if ($('.ly_sample')[0]) {
//             $('.jsMatchHeight.bl_sample').matchHeight();
//             $('.jsMatchHeight.bl_sample').matchHeight();
//             if ($(window).width() > 751) {
//                 $('.jsMatchHeight.bl_sample').matchHeight();
//             }
//         }
//     });
// });

