/**
 * body fadeIn
 *
 */
//$(window).on('load', function () {
//    $('body').animate({
//        opacity: 1
//    }, 300);
//});

/**
 * smooth scroll
 *
 */
$(function () {
	$('a[href^="#"]').not($('.js_pageTop a[href^="#"]')).click(function () {
		var $headerHight = $('header').outerHeight(true);
		var adjust = $headerHight + 0;

		var speed = 400;
		var href = $(this).attr("href");
		var target = $(href == "#" || href == "" ? "html" : href);
		var position = target.offset().top - adjust;
		$("body,html").animate({
			scrollTop: position
		}, speed, "swing");
		return false;
	});
});

//別ページからのアンカーリンク設定
$(window).on('load', function(){
	//アンカーリンク取得
	var hash = location.hash;
	//アンカーリンクがあった場合
	if($(hash).length){

		var $headerHight = $('header').outerHeight(true);
		var adjust = $headerHight + 0;

		var speed = 400;

		//console.log(hash);

		//var href = $(this).attr("href");
	
		var target = $(hash);

		var position = target.offset().top - adjust;
		$("body,html").animate({
			scrollTop: position
		}, speed, "swing");

		location.hash = "";
		history.replaceState("", "", location.pathname);

		return false;
	}
});

/**
 * back top
 *
 */
//$(document).ready(function () {
//    var bodyHeight = $('body').outerHeight(true);
//    var pagetop = $(".js_pageTop");
//    pagetop.hide();
//    $(window).on("scroll", function () {
//        if ($(this).scrollTop() > bodyHeight) {
//            pagetop.fadeIn("fast");
//        } else {
//            pagetop.fadeOut("fast");
//        }
//
//        var scrollHeight = $(document).height();
//        var scrollPosition = $(window).height() + $(window).scrollTop();
//        var footHeight = $(".js_footer").innerHeight();
//
//        if (scrollHeight - scrollPosition <= footHeight) {
//            pagetop.css({
//                "position": "absolute",
//                "bottom": footHeight + 10
//            });
//        } else {
//            pagetop.css({
//                "position": "fixed",
//                "bottom": "10px"
//            });
//        }
//    });
//    pagetop.click(function () {
//        $('body,html').animate({
//            scrollTop: 0
//        }, 500);
//        return false;
//    });
//});

/**
 * Fixed nav(SP)
 *
 */
$(document).ready(function () {
	var bodyHeight = $('body').outerHeight(true);
	var fixedNav = $(".js_fixedNav");
	//    fixedNav.hide();
	$(window).on("scroll", function () {
		//        if ($(this).scrollTop() > bodyHeight) {
		//            fixedNav.fadeIn("fast");
		//        } else {
		//            fixedNav.fadeOut("fast");
		//        }

		var scrollHeight = $(document).height();
		var scrollPosition = $(window).height() + $(window).scrollTop();
		var footHeight = $(".js_footer").innerHeight();

		if (scrollHeight - scrollPosition <= footHeight) {
			fixedNav.css({
				"position": "absolute",
				"bottom": footHeight + 0
			});
		} else {
			fixedNav.css({
				"position": "fixed",
				"bottom": "0"
			});
		}
	});
});

/**
 * mainPadding setting
 *
 */
$(window).on("load resize", function () {
	var $headerHight = $('header').outerHeight(true);
	//    var $Main = $('main');
	var $Main = $('.ly_contentWrap');
	$Main.css({
		paddingTop: $headerHight
	});
});

/**
 * js_adjust_header_scroll
 *
 */
$(window).on("scroll", function () {
	$(".ly_header").css("left", -$(window).scrollLeft());
});

$(function () {

//	var js_language_select = $(".js_language_select");

	//console.log(js_language_select);

	$(".js_language_select").on("click", function (e) {
		
		if ($(this).hasClass("is-active")) {
			//         bodyScrollLock.enableBodyScroll($headerTop);
			//        bodyScrollLock.clearAllBodyScrollLocks();
			$(this).removeClass("is-active");

		} else {

			//         bodyScrollLock.disableBodyScroll($headerTop);
			$(this).addClass("is-active");

		}
		e.preventDefault();
	});

	$(".js_language_link").on("click", function (e) {

		$(".js_language_select").removeClass("is-active");

	});

});


/**
 * js_adjust_footer_scroll
 *
 */
//$(window).on("scroll", function () {
//    $(".js_pageTop").css("left", -$(window).scrollLeft());
//});

/**
 * reload breakpoint
 *
 */
//if ($('.ly_sample')[0]) {
//    const breakPoint01 = 769;
//    const breakPoint02 = 768;
//    let resizeFlag01;
//    let resizeFlag02;
//
//    window.addEventListener('load', function () {
//        if (breakPoint01 < window.innerWidth) {
//            resizeFlag01 = false;
//        } else {
//            resizeFlag01 = true;
//        }
//        resizeWindow01();
//    }, false);
//
//    window.addEventListener('load', function () {
//        if (breakPoint02 > window.innerWidth) {
//            resizeFlag02 = false;
//        } else {
//            resizeFlag02 = true;
//        }
//        resizeWindow02();
//    }, false);
//
//    const resizeWindow01 = function () {
//        window.addEventListener('resize', function () {
//            if (breakPoint01 < window.innerWidth && resizeFlag01) {
//                window.location.reload();
//                resizeFlag01 = false;
//            } else if (breakPoint01 >= window.innerWidth && !(resizeFlag01)) {
//                resizeFlag01 = true;
//            }
//        }, false);
//    }
//    const resizeWindow02 = function () {
//        window.addEventListener('resize', function () {
//            if (breakPoint02 > window.innerWidth && resizeFlag02) {
//                window.location.reload();
//                resizeFlag02 = false;
//            } else if (breakPoint01 <= window.innerWidth && !(resizeFlag02)) {
//                resizeFlag02 = true;
//            }
//        }, false);
//    }
//}

/**
 * toggle menu
 *
 */
if ($(window).width() < 769) {
	var $window = $(window);
	var $nav = $(".bl_nav");
	//var $headerTop = $(".js_header");

	function scroll_control(event) {
		event.preventDefault();
	}

	function no_scroll() {
		document.addEventListener("mousewheel", scroll_control, {
			passive: false,
		});
		document.addEventListener("touchmove", scroll_control, {
			passive: false,
		});
	}

	function return_scroll() {
		document.removeEventListener("mousewheel", scroll_control, {
			passive: false,
		});
		document.removeEventListener("touchmove", scroll_control, {
			passive: false,
		});
	}

	$(".js_nav_btnHamburger").on("click", function (e) {
		e.preventDefault();
		if ($(this).hasClass("active")) {
			//         bodyScrollLock.enableBodyScroll($headerTop);
			//        bodyScrollLock.clearAllBodyScrollLocks();
			$("body").css({
				overflow: "auto"
			});
		} else {
			//        bodyScrollLock.disableBodyScroll($headerTop);
			$("body").css({
				overflow: "hidden"
			});
		}
		$(this).stop().toggleClass("active");
		$(".js_overlay").stop().fadeToggle();
		$(".bl_nav_list_wrapper").stop().slideToggle();
		$(".bl_nav_list_wrapper_inner").stop().toggleClass('on');
	});

	$(".js_overlay").on("click", function (e) {
		e.preventDefault();
		if ($(".js_nav_btnHamburger").hasClass("active")) {
			//         bodyScrollLock.enableBodyScroll($headerTop);
			//        bodyScrollLock.clearAllBodyScrollLocks();
			$("body").css({
				overflow: "auto"
			});
		} else {
			//         bodyScrollLock.disableBodyScroll($headerTop);
			$("body").css({
				overflow: "hidden"
			});
		}
		$(".js_nav_btnHamburger").stop().toggleClass("active");
		$(".js_overlay").stop().fadeToggle();
		$(".bl_nav_list_wrapper").stop().slideToggle();
		$(".bl_nav_list_wrapper_inner").stop().toggleClass('on');
	});

	$(".js_nav_link").on("click", function (e) {
		e.preventDefault();
		if ($(".js_nav_btnHamburger").hasClass("active")) {
			//         bodyScrollLock.enableBodyScroll($headerTop);
			//        bodyScrollLock.clearAllBodyScrollLocks();
			$("body").css({
				overflow: "auto"
			});
		} else {
			//         bodyScrollLock.disableBodyScroll($headerTop);
			$("body").css({
				overflow: "hidden"
			});
		}
		$(".js_nav_btnHamburger").stop().toggleClass("active");
		$(".js_overlay").stop().fadeToggle();
		$(".bl_nav_list_wrapper").stop().slideToggle();
		$(".bl_nav_list_wrapper_inner").stop().toggleClass('on');
	});

	$(".js_fixedNav_access_link").on("click", function (e) {
		e.preventDefault();
		if ($(".js_nav_btnHamburger").hasClass("active")) {
			//         bodyScrollLock.enableBodyScroll($headerTop);
			//        bodyScrollLock.clearAllBodyScrollLocks();
			$("body").css({
				overflow: "auto"
			});
			$(".js_nav_btnHamburger").stop().toggleClass("active");
			$(".js_overlay").stop().fadeToggle();
			$(".bl_nav_list_wrapper").stop().slideToggle();
			$(".bl_nav_list_wrapper_inner").stop().toggleClass('on');
		}

	});

	


}
